import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import Img from "gatsby-image"
import { Container, SectionTitle, ContactBlock, CtaButton, ImageLeft, ContactForm, PhoneLink } from "../styles/styles"
import SEO from "../components/seo"
import SmallBanner from "../components/small-banner"
import image from "../images/contact.jpg"

export default ({ data }) => {
	let service = data.allSitePage.edges[0].node.context
	let eachService = service.content.split(',')
	return (
		<Layout>
			<SEO title="Service" />
			<SmallBanner image={image} position="center top">
				<Container style={{ maxWidth: '1440px' }}>
					<h1>{service.name}</h1>
				</Container>
			</SmallBanner>
			<Container style={{ paddingBottom: 120 }}>
				<SectionTitle>Our {service.name} {service.shortname !== 'coaching' ? 'courses include:' : 'includes:'}</SectionTitle>
				<ul>
					{eachService.map((service, index) => (
						<li key={index}>{service}</li>
					))}
				</ul>
				{service.para &&
					<p>
						{service.para}
					</p>
				}
			</Container>
		</Layout>
	)
}

export const query = graphql`
    query($path: String!) {
        allSitePage(filter: { path: { eq: $path } }) {
            edges {
                node {
                    context {
												name
												shortname
                        content
												para
                    }
                }
            }
        }
    }
`